



































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'VideoModal',
})
export default class VideoModal extends Vue {
  @Prop({ type: String, required: true }) readonly videoToShow!: string
  @Prop({ type: String, required: true }) readonly videoUrl!: string
  @Prop({ type: Function, required: true }) readonly switchMode!: (
    mode: 'full' | 'highlight'
  ) => void
}
