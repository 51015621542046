























import { Component } from 'vue-property-decorator'
import { EliteligaWidget } from '@russmedia-frontend/nordstern-eliteliga'
import type { SoccerLeague } from '@russmedia-frontend/nordstern-eliteliga'
import { ApolloOperationVariables } from '~base/common/types'
import EliteligaFeature from '~base/mixins/EliteligaFeature'
import VideoModal from '~components/eliteliga/VideoModal.vue'
import '@russmedia-frontend/nordstern-eliteliga/nordstern-eliteliga.css'

@Component<EliteligaUpcomingAndHighlightsWidget>({
  apollo: {
    multileague: {
      query: require('~base/graphql/queries/UpcomingAndHighlights.gql'),
      prefetch(): ApolloOperationVariables {
        return {
          leagues: this.leagues,
        }
      },
      variables(): ApolloOperationVariables {
        return {
          leagues: this.leagues,
        }
      },
    },
  },
  components: {
    EliteligaWidget,
    VideoModal,
  },
})
export default class EliteligaUpcomingAndHighlightsWidget extends EliteligaFeature {
  private multileague: SoccerLeague[] = []

  gotoSpecial(data: { league: string }) {
    let url = '/ressort/eliteliga'
    if (data.league === 'Regionalliga West') {
      url = '/ressort/regionalliga'
    }
    this.$router.push(url)
  }

  gotoMediathek(data: { league: string }) {
    let url = '/ressort/eliteliga/mediathek'
    if (data.league === 'Regionalliga West') {
      url = '/ressort/regionalliga/mediathek'
    }
    this.$router.push(url)
  }
}
