import { Component, Vue, Prop } from 'vue-property-decorator'
import type { OnVideoData } from '@russmedia-frontend/nordstern-eliteliga'

@Component
export default class EliteligaFeature extends Vue {
  public showVideoBox: boolean = false
  public highlightVideoUrl: string | undefined = undefined
  public videoToShow: 'highlight' | 'full' = 'highlight'
  public videoData: OnVideoData | undefined = undefined
  @Prop({ type: Array, default: () => ['RLW', 'Eliteliga_Grunddurchgang'] })
  readonly leagues!: string[]

  onVideo(videoData: OnVideoData) {
    this.videoData = videoData
    const isLoggedIn = this.$store.getters['paywall/isLoggedIn']
    const subscriber = this.$store.getters['paywall/subscriber']

    if (!isLoggedIn || subscriber.expired) {
      this.$store.dispatch(`paywall/executeExperience`, { tags: { nodes: [] } })
    } else {
      this.showVideoBox = true
      this.highlightVideoUrl = videoData.videoUrl
      this.$store.dispatch('modal/showModal', {
        showCloseButton: true,
        name: `gameHighlights`,
        slotClass: `max-w-xl`,
      })
    }
  }

  switchMode(mode: 'highlight' | 'full'): void {
    this.videoToShow = mode
    if (this.videoToShow === 'full') {
      this.highlightVideoUrl = this.videoData!.gameUrl
    } else {
      this.highlightVideoUrl = this.videoData!.highlightUrl
    }
  }

  onClose() {
    this.highlightVideoUrl = undefined
    this.showVideoBox = false
  }
}
